import { findIndex } from 'lodash/core'

/**
 * 해당 파일 사이즈를 최대 단위로 표현, 소숫점 한자리까지 표현한다.
 * @param {Number} number - file 의 byte 사이즈
 * @returns {String} - ex) 49.1MB
 */
export function returnFileSize (number) {
  if (number < 1024) {
    return number + 'bytes'
  } else if (number > 1024 && number < 1048576) {
    return (number / 1024).toFixed(1) + 'KB'
  } else if (number > 1048576) {
    return (number / 1048576).toFixed(1) + 'MB'
  }
}

/**
 * timestamp나 datetime 문자열을 원하는 포맷으로 변경함
 * @param {String | Number} date - timestamp(INT) or datetime(STRING)
 * @param {String} f - 포맷 문자열
 * @returns {String} - 변환된 날짜시간 포맷 문자열
 */
export function dateFormat (date, f) {
  if (!f || f === '') f = 'yyyy-MM-dd HH:mm:ss'
  var dayOfWeek = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat']
  var dayOfWeekKo = ['일', '월', '화', '수', '목', '금', '토']
  var d = new Date(date)

  return f.replace(/(yyyy|yy|MM|dd|E|K|hh|mm|ss|fff|a\/p)/gi, function ($1) {
    switch ($1) {
      case 'yyyy': return d.getFullYear()
      case 'yy': return zf(d.getFullYear() % 100, 2)
      case 'MM': return zf(d.getMonth() + 1, 2)
      case 'dd': return zf(d.getDate(), 2)
      case 'E': return dayOfWeek[d.getDay()]
      case 'K': return dayOfWeekKo[d.getDay()]
      case 'HH': return zf(d.getHours(), 2)
      case 'hh': return zf((d.getHours() % 12 ? d.getHours() % 12 : 12), 2)
      case 'mm': return zf(d.getMinutes(), 2)
      case 'ss': return zf(d.getSeconds(), 2)
      case 'fff': return zf(d.getMilliseconds(), 3)
      case 'a/p': return d.getHours() < 12 ? 'AM' : 'PM'
      default: return $1
    }
  })
}

export function timestampToDateObject (ts) {
  const len = parseInt(ts).toString().length
  if (len === 13) {
    return new Date(ts)
  } else if (len === 10) {
    ts *= 1000
    return new Date(ts)
  } else {
    return null
  }
}

/**
 * 문자열 앞에 0을 채워넣어 원하는 길이로 만들어 반환
 * @param {String | Number} str - 변환하고자 하는 문자열(숫자도 가능)
 * @param {Number} len - 원하는 길이
 * @returns {String} 변환된 문자열
 */
function zf (str, len) {
  str = String(str)
  if (str.length >= len) {
    return str.substring(0, len)
  } else {
    let newStr = ''
    for (let i = 0; i < len - str.length; i++) {
      newStr += '0'
    }
    newStr += str
    return newStr
  }
}

export function expireDateCheck (startDate, endDate) {
  const date = new Date()
  const utc = String(Date.UTC(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate())).slice(0, 10)
  const result = (utc < startDate) ? 'before' : ((utc > endDate) ? 'after' : '')

  return result
}

export function scheduleOptionFormat (langCd, typeCd, typeOption) {
  let str = ''
  if (langCd === 'ko') {
    const dayOfWeek = ['월', '화', '수', '목', '금', '토', '일']

    switch (typeCd.toLowerCase()) {
      case 'everyday':
        str = '매일'
        break
      case 'day':
        for (let i = 0; i < typeOption.length; i++) {
          if (i === 0) {
            str = dayOfWeek[typeOption[i]]
          } else {
            str += ',' + dayOfWeek[typeOption[i]]
          }
        }
        break
      case 'date':
        str = '매월 ' + typeOption
        break
    }
  } else if (langCd === 'en') {
    const dayOfWeek = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun']
    switch (typeCd.toLowerCase()) {
      case 'everyday':
        str = 'Everyday'
        break
      case 'day':
        for (let i = 0; i < typeOption.length; i++) {
          if (i === 0) {
            str = dayOfWeek[typeOption[i]]
          } else {
            str += ',' + dayOfWeek[typeOption[i]]
          }
        }
        break
      case 'date':
        str = typeOption + ' of Every Month'
        break
    }
  }
  return str
}

export function getExistInArray (array, value) {
  const index = findIndex(array, o => { return o === value })
  if (index >= 0) {
    return true
  } else {
    return false
  }
}

<template>
  <v-app>
    <HeadNav></HeadNav>
    <LeftNav v-if="layout == 'LayoutDefault'"></LeftNav>
    <v-main>
      <v-container fluid class="main-container">
        <router-view></router-view>
      </v-container>
    </v-main>
    <FooterNav />
    <Alert></Alert>
  </v-app>
</template>

<script>
const HeadNav = () => import('@/components/Header.vue')
const LeftNav = () => import('@/components/LeftNav.vue')
const FooterNav = () => import('@/components/Footer.vue')

const Alert = () => import('@/components/Alert.vue')

export default {
  name: 'App',
  components: {
    HeadNav, 
    LeftNav,
    FooterNav,
    Alert
  },
  computed: {
    layout () {
      return 'layout'
      // if (this.$route.meta.layout) {
      //   return this.$route.meta.layout
      // } else {
      //   return 'LayoutDefault'
      // }
    }
  },
  data: () => ({
  }),
  
  created() {
  }
};
</script>

<style>
.v-application {font-family: 'Noto Sans KR', sans-serif !important;}
.main-container  {padding: 20px;}

@media (max-width: 480px) {
  .main-container  {padding: 0;}
}
</style>
const authStore = {
	namespaced: true,

	state: {
		isSignedIn: false,
		joinDialogVisiable: false,
		accessTokenKey: null,
		accessTokenDuration: 0,
		accessTokenExpireDate: null,
		walletAddress: null,
		blockchainCd: '',
		networkCd: '',
		chainId: '',
		mainnetYN: 'N'
	},

	getters: {
		getIsSignedIn: (state) => state.isSignedIn,
		getJoinDialogVisiable: (state) => state.joinDialogVisiable,
		getAccessTokenKey: (state) => state.accessTokenKey,
		getAccessTokenDuration : (state) => state.accessTokenDuration,
		getAccessTokenExpireDate: (state) => state.accessTokenExpireDate,
		getWalletAddress: (state) => state.walletAddress,
		getBlockchainCd: (state) => state.blockchainCd,
		getChainId: (state) => state.chainId,
		getMainnetYN: (state) => state.mainnetYN,
		getNetworkCd: (state) => state.networkCd,
	},

	mutations: {
		_setIsSignedIn : (state, payload) => state.isSignedIn = payload,
		_setJoinDialogVisiable : (state, payload) => state.joinDialogVisiable = payload,
		_setAccessTokenKey: (state, payload) => state.accessTokenKey = payload,
		_setAccessTokenDuration: (state, payload) => state.accessTokenDuration = payload,
		_setAccessTokenExpireDate: (state, payload) => state.accessTokenExpireDate = payload,
		_setWalletAddress: (state, payload) => state.walletAddress = payload,
		_setBlockchainCd: (state, payload) => state.blockchainCd = payload,
		_setChainId: (state, payload) => {
			state.chainId = payload
			switch (payload) {
				case '0x1':
					state.networkCd = 'ethereum'; break
				case '0x5':
					state.networkCd = 'goerli'; break
				case '0xaa36a7':
					state.networkCd = 'sepolia'; break
				case '8217':
					state.networkCd = 'klaytn'; break
				case '1001':
					state.networkCd = 'baobab'; break
				default:
					state.networkCd = '';
			}
		},
		_setMainnetYN: (state, payload) => state.mainnetYN = payload,
	},

	actions: {
		setIsSignedIn : ({ commit }, payload) => commit('_setIsSignedIn', payload),
		setJoinDialogVisiable : ({ commit }, payload) =>  commit('_setJoinDialogVisiable', payload),
		setAccessTokenKey: ({ commit }, payload) => commit('_setAccessTokenKey', payload),
		setAccessTokenDuration: ({ commit }, payload) => commit('_setAccessTokenDuration', payload),
		setAccessTokenExpireDate: ({ commit }, payload) => commit('_setAccessTokenExpireDate', payload),
		setWalletAddress: ({ commit }, payload) => commit('_setWalletAddress', payload),
		setBlockchainCd: ({ commit }, payload) => commit('_setBlockchainCd', payload),
		setChainId: ({ commit }, payload) => commit('_setChainId', payload),
		setMainnetYN: ({ commit }, payload) => commit('_setMainnetYN', payload),
		setLogout: ({ commit }) => {
			commit('_setIsSignedIn', false)
			commit('_setAccessTokenExpireDate', null)
			commit('_setAccessTokenDuration', 0)
			commit('_setAccessTokenKey', null)
		},
		setWalletLogout: ({ commit }) => {
			commit('_setWalletAddress', null)
			commit('_setBlockchainCd', '')
			commit('_setChainId', '')
			commit('_setMainnetYN', 'N')
		},
		setLogin: ({ commit }, payload) => {
			commit('_setIsSignedIn', true)
			commit('_setAccessTokenExpireDate', payload.accessTokenExpireDate)
			commit('_setAccessTokenDuration', payload.accessTokenDuration)
			commit('_setAccessTokenKey', payload.accessTokenKey)
		}
	},
}

export default authStore

import Vue from 'vue'
import { dateFormat } from '../assets/js/common'

/**
  tsDateformat - timestamp로 넘어온 값을 datetime format으로 변환하여 보여줌
**/
Vue.filter('tsDateformat', function (value, format) {
  if (!value) return ''
  if (format === '' || !format) format = 'yyyy-MM-dd HH:mm'
  if (parseInt(value) < 0) return ''
  const len = parseInt(value).toString().length
  if (len < 10) {
    return ''
  } else if (len === 10) {
    value = parseInt(value) * 1000
  }
  value = parseInt(value)
  return dateFormat(value, format)
})

/**
  날짜시간(datetime) 데이터를 원하는 형태로 변환
**/
Vue.filter('dtDateformat', function (value, format) {
  if (!value) return ''
  if (format === '' || !format) format = 'yyyy-MM-dd HH:mm'

  return dateFormat(value, format)
})

/**
  숫자를 3자리마다 , 추가하여 반환
  pre: 접두사, suf: 접미사
**/
Vue.filter('numberformat', function (value, pre, suf) {
  if (!value) return '0'
  value = value * 1
  return pre + value.toFixed(0).replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, '$1,') + suf
})

/**
  전화번호 데이터를 원하는 형태로 변환
**/
Vue.filter('phoneformat', function (value) {
  if (!value) return ''
  let formatNum = ''

  if (value.length === 11) {
    formatNum = value.replace(/(\d{3})(\d{4})(\d{4})/, '$1-$2-$3')
  } else if (value.length === 8) {
    formatNum = value.replace(/(\d{4})(\d{4})/, '$1-$2')
  } else {
    if (value.indexOf('02') === 0) {
      if (value.length === 9) {
        formatNum = value.replace(/(\d{2})(\d{3})(\d{4})/, '$1-$2-$3')
      } else {
        formatNum = value.replace(/(\d{2})(\d{4})(\d{4})/, '$1-$2-$3')
      }
    } else {
      if (value.length === 10) {
        formatNum = value.replace(/(\d{3})(\d{3})(\d{4})/, '$1-$2-$3')
      } else {
        formatNum = value.replace(/(\d{3})(\d{4})(\d{4})/, '$1-$2-$3')
      }
    }
  }
  return formatNum
})

import detectEthereumProvider from '@metamask/detect-provider'
import Web3 from 'web3'
import store from '@/store'
import { APP_MAINNET_YN } from '@/assets/js/url'
import { authModule } from './authModule'

/*
  메타마스크 지갑을 제어하기 위한 클래스
*/

export default class MyMetamask {
  constructor() {
    this.wallet = null
    this.installed = false
  }

  async call() {
    const ethProvider = await detectEthereumProvider()
    if (ethProvider) {
      this.installed = true
      this.init(ethProvider)
    }      
  }

  init(provider) {
    try {
      if (provider !== window.ethereum) {
        console.error('Do you have multiple wallets installed?')
      } else {
        this.wallet = window.ethereum
        window.web3 = new Web3(window.ethereum)
      }

      this.wallet.on('chainChanged', (chainId) => {
        this.handleChainChanged(chainId)
      })
      this.wallet.on('accountsChanged', (accounts) => {
        let walletAddress = store.getters['authStore/getWalletAddress']
        if (accounts[0] != null && walletAddress != null && authModule.isLogin()) {
          alert('계정이 변경되어 로그아웃합니다.')
          authModule.logout()
        }
        // this.handleAccountsChanged(accounts[0])
      })
      // this.wallet.on('disconnect', () => {
      //   console.log('disconnect')
      //   authModule.logout()
      // })

      // chain id  초기값을 조회
      this.wallet
        .request({ method: 'eth_chainId' })
        .then((chainId) => {
          this.handleChainChanged(chainId)
        })
        .catch((err) => {
          console.error(err)
        })

    } catch (e) {
        console.error(e.message)
    }
  }

  // 로그인 이후 체인 변경의 경우만 적용되도록 함
  async handleChainChanged(_chainId) {
    // let account = store.getters['authStore/getWalletAddress']
    const storeBlockchain_cd = store.getters['authStore/getBlockchainCd']
    const storeChainId = store.getters['authStore/getChainId']
    const storeMainnetYN = store.getters['authStore/getMainnetYN']
    const mainChainId = '0x1'
    const _mainnet_yn = (_chainId == mainChainId) ? 'Y' : 'N'
    
    if ((storeBlockchain_cd != 'ETH' && storeBlockchain_cd != '') || authModule.isLogin() == false)
      return

    // 메인넷 사용여부 설정에 따라 해당 계정만 로그인하도록 막음
    if (_mainnet_yn !== APP_MAINNET_YN) {
      if (APP_MAINNET_YN == 'N') {
        alert('현재 DCL은 테스트넷만 로그인 가능합니다.\n지갑 네트워크 정보를 먼저 변경해 주세요.')
      } else {
        alert('현재 DCL은 메인넷만 로그인 가능합니다.\n지갑 네트워크 정보를 먼저 변경해 주세요.')
      }
      authModule.logout()
      return
    }

    // Sepolia(0xaa36a7), Goerli(0x5)만 가능
    if (_mainnet_yn == 'N' && _chainId != 0xaa36a7 && _chainId != 0x5) {
      alert('사용할 수 없는 네트워크 정보로 변경되어 로그아웃 합니다.' + _chainId)
      authModule.logout()
      return
    }

    // 메인넷여부가 변경될 경우 로그아웃 시킴
    if (storeMainnetYN != _mainnet_yn && storeChainId != '') {
      alert('네트워크가 변경되어 로그아웃 합니다.')
      authModule.logout()
      return
    }
    // 메인넷이 아닐 경우, chain id만 변경함
    if (storeMainnetYN == 'N' && storeChainId != '') {
      store.dispatch('authStore/setChainId', _chainId)
      return
    }

    // 네트워크 정보 저장
    store.dispatch('authStore/setChainId', _chainId)
    store.dispatch('authStore/setMainnetYN', _mainnet_yn)
  }

  async handleAccountsChanged(account) {
    let walletAddress = store.getters['authStore/getWalletAddress']
    let blockchain_cd = store.getters['authStore/getBlockchainCd']

    if (blockchain_cd != 'ETH' && blockchain_cd != '')
      return
    
    if (account == null && walletAddress != null) {
      alert('지갑 정보가 변경되어 로그아웃 합니다.')
      authModule.logout()
    }
    // } else if (account !== walletAddress) {
      // alert('AccountsChanged')
      // const blockchain_cd = 'ETH'
      // const mainnet_yn = store.getters['authStore/getMainnetYN']
      // const walletInfo = {wallet_address: account, mainnet_yn: mainnet_yn, blockchain_cd: blockchain_cd}
      // console.log(walletInfo)

      // // 가입여부 확인 후 회원가입 or 로그인
      // await authModule.memberCheck(walletInfo)
    // }
  }

  async connectWallet(){
    await this.wallet
      .request({ method: 'eth_requestAccounts' })
      .then((accounts) => {
        this.handleAccountsChanged(accounts[0]).then(() => {
          this.wallet
          .request({ method: 'eth_chainId' })
          .then((chainId) => {
            const blockchain_cd = 'ETH'
            const mainChainId = '0x1'
            const mainnet_yn = (chainId == mainChainId) ? 'Y' : 'N'

            // 메인넷 사용여부 설정에 따라 해당 계정만 로그인하도록 막음
            if (mainnet_yn !== APP_MAINNET_YN) {
              if (APP_MAINNET_YN == 'N') {
                alert('현재 DCL은 테스트넷만 로그인 가능합니다.\n지갑 네트워크 정보를 먼저 변경해 주세요.')
              } else {
                alert('현재 DCL은 메인넷만 로그인 가능합니다.\n지갑 네트워크 정보를 먼저 변경해 주세요.')
              }
              authModule.logout()
              return
            }

            // Sepolia(0xaa36a7), Goerli(0x5)만 가능
            if (mainnet_yn == 'N' && chainId != 0xaa36a7 && chainId != 0x5) {
              alert(`CHAIN ID ${chainId}는 사용할 수 없는 네트워크 정보입니다.`)
              authModule.logout()
              return
            }

            // 네트워크 정보 저장
            store.dispatch('authStore/setChainId', chainId)
            store.dispatch('authStore/setMainnetYN', mainnet_yn)


            const walletInfo = { wallet_address: accounts[0], mainnet_yn: mainnet_yn, blockchain_cd: blockchain_cd }
            // console.log(walletInfo)

            // 가입여부 확인 후 회원가입 레이어 팝업 or 로그인
            authModule.memberCheck(walletInfo)

          })
          .catch((err) => {
            console.error(err)
          }) 
        })
      })
      .catch((err) => {
        console.error(err)
        if (err.code === 4001) {
          // EIP-1193 userRejectedRequest error
          // If this happens, the user rejected the connection request.
          alert('Please connect to MetaMask.')
        } else if (err.code === -32002) {
          alert("Please sign in to your MetaMask.")
        } else {
          console.error(err)
        }
      })
  }

  async join(){
    await this.wallet
      .request({ method: 'eth_requestAccounts' })
      .then((accounts) => {
        this.handleAccountsChanged(accounts[0]).then(() => {
          this.wallet
          .request({ method: 'eth_chainId' })
          .then((_chainId) => {
            const blockchain_cd = 'ETH'
            const mainnet_yn = store.getters['authStore/getMainnetYN']

            // 메인넷 사용여부 설정에 따라 해당 계정만 가입하도록 막음
            if (mainnet_yn !== APP_MAINNET_YN) {
              if (APP_MAINNET_YN == 'N') {
                alert('현재 DCL은 테스트넷만 가입 가능합니다.\n지갑 네트워크 정보를 먼저 변경해 주세요.')
              } else {
                alert('현재 DCL은 메인넷만 가입 가능합니다.\n지갑 네트워크 정보를 먼저 변경해 주세요.')
              }
              authModule.logout()
              return
            }
            
            // Sepolia(0xaa36a7), Goerli(0x5)만 가능
            if (mainnet_yn == 'N' && _chainId != 0xaa36a7 && _chainId != 0x5) {
              alert(`CHAIN ID ${_chainId}는 사용할 수 없는 네트워크 정보입니다.`)
              authModule.hiddenJoinDialog()
              authModule.logout()
              return
            }
       
            const walletInfo = { wallet_address: accounts[0], mainnet_yn: mainnet_yn, blockchain_cd: blockchain_cd }
            // 회원가입
            authModule.join(walletInfo)
          })
        })
      })
      .catch((err) => {
        console.error(err)
      })
  }

  installWallet(){
    window.open('https://metamask.io/')
  }

  // async signSample(){
  //   const nonce = '836xc97e'
  //   const exampleMessage = 'DCL 사이트에 가입하시겠습니까? nonce : ' + nonce
  //   try {
  //     const from = store.getters['authStore/getWalletAddress']
  //     const sign = await this.wallet.request({
  //       method: 'personal_sign',
  //       params: [exampleMessage, from, ''],
  //     })
  //     console.log(sign)
  //   } catch (err) {
  //     console.error(err)
  //   }
  // }
}
const memberStore = {
	namespaced: true,

	state: {
			member: {},
	},

	getters: {
			getMember: (state) => state.member,
	},

	mutations: {
			_setMember     : (state, payload) => (state.member = { ...payload }),
			_deleteMember  : (state) => (state.member = {}),
	},

	actions: {
			setMember     : ({ commit }, payload) => commit('_setMember', payload),
			deleteMember  : ({ commit }) => commit('_deleteMember'),
	},
}

export default memberStore

import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store'
import axios from 'axios'
import { authModule } from '@/module/authModule'


Vue.use(VueRouter)

const Home = () => import('../views/home.vue')
const Auth = () => import('../views/auth/authView.vue')

const CollectionCreate = () => import('../views/my/collectionCreate.vue')
const CollectionEdit = () => import('../views/my/collectionEdit.vue')
const ItemCreate = () => import('../views/my/itemCreate.vue')
const ItemEdit = () => import('../views/my/itemEdit.vue')

const CollectionList = () => import('../views/collection/collectionList.vue')
const CollectionDetail = () => import('../views/collection/collectionDetail.vue')
const ItemDetail = () => import('../views/collection/itemDetail.vue')

const myItemList = () => import('../views/mypage/myItemList.vue')

const routes = [
  { path: '/', name: 'home', component: Home },
	{ path: '/home', name: 'homeView', component: Home },

  { path: '/auth', name: 'auth', component: Auth, meta: { /*layout: 'LayoutWithoutNav'*/ } },
	{ path: '/mypage/itemList', name: 'myItemList', component: myItemList, meta: { authRequired: true } },
	
  { path: '/my/collection/create', name: 'collectionCreate', component: CollectionCreate, meta: { authRequired: true } },
	{ path: '/my/collection/:collection_uuid/edit', name: 'collectionEdit', component: CollectionEdit, props: true, meta: { authRequired: true } },
	{ path: '/my/collection/:collection_uuid/create', name: 'itemCreate', component: ItemCreate, props: true, meta: {authRequired: true } },
	{ path: '/my/collection/:collection_uuid/:item_uuid/edit', name: 'itemEdit', component: ItemEdit, props: true, meta: {authRequired: true } },

	{ path: '/collection', name: 'CollectionList', component: CollectionList, props: true, meta: { authRequired: false }},
	{ path: '/collection/:collection_uuid', name: 'CollectionDetail', component: CollectionDetail, props: true, meta: { authRequired: false }},
	{ path: '/collection/:collection_uuid/:item_uuid', name: 'ItemDetail', component: ItemDetail, props: true, meta: { authRequired: false }}
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach(async (to, from, next) => {


	// ! : 로그인된 상태로 로그인 페이지로 진입
	// if (to.matched.some((record) => record.path === '/auth') &&
	// 	store.getters['authStore/getIsSignedIn'] === true) {
	// 	next({ path: '/home' })
	// }



	// ! : 로그인이 필요한 페이지라면 로그인여부 확인
	if (to.matched.some((record) => record.meta.authRequired)) {
		if (authModule.isLogin()) {
			next()
		} else {
			next({ path: '/auth' })
		}
	} else {
		next()
	}



	// ! : axios header 인증값이 없을 경우 셋팅해 줌.
	if(!axios.defaults.headers.common['Authorization']){
		axios.defaults.headers.common['Authorization'] = `Bearer ${store.getters['authStore/getAccessTokenKey']}`
	}


	

	// ! : 페이지를 갱신 할때마다 토큰 유효시간 확인, 인증 요청.
	if(to.matched.some((record) => record.path !== '/auth') && store.getters['authStore/getAccessTokenExpireDate'] && (store.getters['authStore/getAccessTokenExpireDate'] < Math.floor(new Date().getTime() / 1000))){
		await authModule.renewToken()
	}

})

const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
	return originalPush.call(this, location).catch(err => {
		if (err.name !== 'NavigationDuplicated') throw err
	})
}

export default router

import axios from 'axios'

export default {
  async addMember(reqData) {
    return axios.post('/member', reqData)
  },
  async getMember() {
    return axios.get('/member')
  },
  async getPing() {
    return axios.get('/member/ping')
  },
  async getToken() {
    // 액세스 토큰 갱신
    return axios.get('/member/token')
  },
  async login(reqData) {
    return axios.post('/member/login', reqData)
  },
  async logout() {
    return axios.post('/member/logout')
  },
  async check(reqData) {
    return axios.post('/member/check', reqData)
  }
}
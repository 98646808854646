let API_URL = 'https://digitalcontentlicense.org/api'
let FILE_URL = 'https://digitalcontentlicense.org/nftfile'
const APP_MAINNET_YN = 'Y'

if (location.hostname.startsWith('192.168.0.152')) {
  // 개발용
  API_URL = 'http://192.168.0.152:8022/api'
  FILE_URL = 'http://192.168.0.152:8022/nftfile'
} else if (location.hostname.startsWith('192.168.')) {
  // 내부망 테스트용
}

export { API_URL, FILE_URL, APP_MAINNET_YN }

import router from '@/router'

export const commonMixin = {
  data () {
    return {
    }
  },
  methods: {
    alertSetting (message, nextUrl, routerPath) {
      const payload = { message: message, nextUrl: nextUrl, routerPath: routerPath }
      this.$store.dispatch('alert', payload)
      this.$refs['alert-modal'].open()
    },
    headerMenuSetting (menuCd) {
      const payload = { menuCd: menuCd }
      this.$store.dispatch('headerMenu', payload)
    },
    t (str, fallbackStr) {
      // use `$te` and `$t
      // i18n 초기값을 입력하여 현재 입력값을 명확히 보여주기 위해 만든 함수
      return this.$t && this.$te
        ? (this.$te(str) ? this.$t(str) : fallbackStr)
        : (fallbackStr || str)
    },
    goPage (cd) {
      if (cd == 'myCollection') {
        this.goPageWithParams('myItemList', {tab: 3})
      } else {
        router.push( this.getPage(cd) )
      }
    },
    goPageWithParams (name, params) {
      this.$router.push({ name: name, params: params })
    },
    getPage (cd) {
      let path = ''
      switch(cd) {
        case 'home':
          path = '/home'; break;
        case 'auth':
          path = '/auth'; break;
        case 'mypage':
          path = '/mypage/itemList'; break;
        case 'collectionList':
          path = '/collection'; break;
      }
      return path
    },
    goCollection(collection_uuid) {
      router.push(`/collection/${collection_uuid}`)
    },
    getContractLink (network_cd, payload) {
      // payload: {addressType: str, hexAddress: str}
      // addressType: address / tx / token
      let baseUrl = ''

      if (network_cd == 'ethereum') {
        baseUrl = 'https://www.etherscan.io/'
      } else if (network_cd == 'goerli') {
        baseUrl = 'https://goerli.etherscan.io/'
      } else if (network_cd == 'sepolia') {
        baseUrl = 'https://sepolia.etherscan.io/'
      } else if (network_cd == 'baobab') {
        baseUrl = 'https://baobab.scope.klaytn.com/'
      } else if (network_cd == 'klaytn') {
        baseUrl = 'https://scope.klaytn.com/'
      }

      return `${baseUrl}${payload.addressType}/${payload.hexAddress}`
    },
    goContractLink (network_cd, payload) {
      router.push( this.getContractLink(network_cd, payload) );
    },
  }
}

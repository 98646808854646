import Vue from 'vue'

import App from './App.vue'
import router from './router'
import store from './store'
import { commonMixin } from '@/mixin/common.js'
import './filter/filters'
import './api'
import MyMetamask from '@/module/MyMetamask'
import myKaikas from '@/module/myKaikas'

import './plugins/vee-validate'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import vuetify from './plugins/vuetify'

/* UI */
import '@/assets/css/common.css'
import '@/assets/css/common.scss'
import '@/assets/font/fonts.css'

Vue.component('ValidationProvider', ValidationProvider)
Vue.component('ValidationObserver', ValidationObserver)

Vue.mixin(commonMixin)


Vue.config.productionTip = false

const walletMetamask = new MyMetamask()
const walletKaikas = new myKaikas()
walletMetamask.call()
walletKaikas.call()

Vue.prototype.$walletMetamask = walletMetamask
Vue.prototype.$walletKaikas = walletKaikas



new Vue({
  router,
  store,

  data () {
    return {
      // publicPath: process.env.BASE_URL
    }
  },

  vuetify,
  render: h => h(App)
}).$mount('#app')

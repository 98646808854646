/**
 * convert object data to form data
 */
 export const formdata = {

  // 회원여부 확인, 로그인, 회원가입
  memberCheck (obj) {
    const {
      wallet_address, mainnet_yn, blockchain_cd
    } = obj
    const d = new FormData()
    d.append('wallet_address', wallet_address)
    d.append('blockchain_cd', blockchain_cd)
    d.append('mainnet_yn', mainnet_yn)
    return d
  },

  file (obj) {
    const d = new FormData()
    d.append('file', obj)
    return d
  },

  collection (obj) {
    const {
      collection_nm,
      desc,
      blockchain_cd,
      network_cd
    } = obj
    const d = new FormData()
    d.append('collection_nm', collection_nm)
    d.append('desc', desc)
    d.append('blockchain_cd', blockchain_cd)
    d.append('network_cd', network_cd)
    return d
  },

  collectionUpdate (obj) {
    const {
      collection_nm, desc
    } = obj
    const d = new FormData()

    d.append('collection_nm', collection_nm)
    d.append('desc', desc)
    return d
  },

  item (obj) {
    const {
      item_nm, desc, collection_uuid
    } = obj
    const d = new FormData()
    d.append('collection_uuid', collection_uuid)
    d.append('item_nm', item_nm)
    d.append('desc', desc)
    return d
  },

  itemUpdate (obj) {
    const {
      item_nm, desc
    } = obj
    const d = new FormData()
    d.append('item_nm', item_nm)
    d.append('desc', desc)
    return d
  },

  itemBuy (obj) {
    const {
      txn_hash
    } = obj
    const d = new FormData()
    d.append('txn_hash', txn_hash)
    return d
  },

  activity (obj) {
    const {
      activity_cd, coin_prc
    } = obj
    const d = new FormData()
    d.append('activity_cd', activity_cd)
    d.append('coin_prc', coin_prc)
    return d
  }
}
/* eslint-disable camelcase */
import { extend } from 'vee-validate'
import { required, email, min, min_value, max } from 'vee-validate/dist/rules'

// Install rule and message.
extend('required', required)
extend('email', email)
extend('min', min)
extend('max', max)
extend('min_value', min_value)

import jwt_decode from 'jwt-decode'
import axios from 'axios'
import store from '@/store'
import { formdata } from '@/module/formdata'
import api from '@/api/member'
import router from '@/router'

export const authModule = {
  async memberCheck(walletInfo) {
    try {
      // 가입여부 확인
      let form = formdata.memberCheck(walletInfo)
      const res = await api.check(form)
      if (res.data.result == 'N') {
        // 가입 dialog 보이기
        store.dispatch('authStore/setJoinDialogVisiable', true)
      } else if (res.data.result == 'Y') {
        // 로그인
        await this.login(form)
      }
    } catch (e) {
      console.error(e.message)
    }
  },
  async join(walletInfo) {
    try {
      let form = formdata.memberCheck(walletInfo)
      await api.addMember(form)
      await this.login(form)
    } catch (e) {
      console.error(e.message)
    }
  },
  isLogin() {
    const isSignedIn = store.getters['authStore/getIsSignedIn']
    const accessTokenKey = store.getters['authStore/getAccessTokenKey']
    const wallterAddress = store.getters['authStore/getWalletAddress']
    if (isSignedIn && accessTokenKey !== null && wallterAddress !== null)
      return true
    else {
      axios.defaults.headers.common['Authorization'] =``
      store.dispatch('authStore/setLogout')
      store.dispatch('authStore/setWalletLogout')
      return false
    }
    
  },
  async login(form) {
    // jwt 토큰이 없다면 로그인
    // form의 wallet_address 값이 store의 wallet_address 값과 다르다면 로그아웃

    if (store.getters['authStore/getAccessTokenKey'] == null) {
      let res = await api.login(form)
      if (res.status === 200) {
        this.updateTokenData(res.data)
        store.dispatch('authStore/setWalletAddress', form.get('wallet_address'))
        store.dispatch('authStore/setBlockchainCd', form.get('blockchain_cd'))
      }
    } else if (form.get('wallet_address') != store.getters['authStore/getWalletAddress']) {
      alert('계정이 변경되어 로그아웃합니다.')
      this.logout()
    }
  },
  async renewToken() {
    const res = await api.getToken()
		if(res.status === 200) {
      this.updateTokenData(res.data)
		}
    return res
  },
  updateTokenData(data) {
    const decodedToken = jwt_decode(data.access_token)
    let payload = {
      accessTokenExpireDate: decodedToken.expire_dt,
      accessTokenDuration: decodedToken.expire_dt - data.server_time,
      accessTokenKey: data.access_token
    }
    axios.defaults.headers.common['Authorization'] = `Bearer ${data.access_token}`
    store.dispatch('authStore/setLogin', payload)
  
  },
  async logout() {
    // console.log('logout')
    if (store.getters['authStore/getIsSignedIn']) {
      axios.defaults.headers.common['Authorization'] =``
      store.dispatch('authStore/setLogout')
      store.dispatch('authStore/setWalletLogout')
      await axios.post('/member/logout').then(() => {
        router.push('/')
      })
    }
  },
  hiddenJoinDialog () {
    store.dispatch('authStore/setJoinDialogVisiable', false)
  }
}

import store from '@/store'
import { APP_MAINNET_YN } from '@/assets/js/url'
import { authModule } from './authModule'

/*
  카이카스 지갑을 제어하기 위한 클래스
*/

export default class myKaikas {
  constructor() {
    this.wallet = null
    this.installed = false
  }

  async call() {
    const klyProvider = window.klaytn
    if (klyProvider) {
      if (klyProvider.isKaikas) {
        this.installed = true
        this.init(klyProvider)
      }
    }
  }

  init(provider) {
    try {
      this.wallet = provider
      // window.caver = new Caver(window.klaytn)
      
      this.wallet.on('networkChanged', (networkID) => {
        this.handleChainChanged(String(networkID))
      })
      this.wallet.on('accountsChanged', (accounts) => {
        this.handleAccountsChanged(accounts)
      })
    } catch (e) {
        console.error(e.message)
    }
  }

  async handleChainChanged(_chainId) {
    let account = store.getters['authStore/getWalletAddress']
    let blockchain_cd = store.getters['authStore/getBlockchainCd']
    let chainId = store.getters['authStore/getChainId']
    const mainChainId = '8217'
    const mainnet_yn = (_chainId == mainChainId) ? 'Y' : 'N'
  
    if (blockchain_cd != 'KLY')
      return

    // 메인넷 사용여부 설정에 따라 해당 계정만 로그인하도록 막음
    if (mainnet_yn !== APP_MAINNET_YN) {
      if (APP_MAINNET_YN == 'N') {
        alert('현재 DCL은 테스트넷만 로그인 가능합니다.\n지갑 네트워크 정보를 먼저 변경해 주세요.')
      } else {
        alert('현재 DCL은 메인넷만 로그인 가능합니다.\n지갑 네트워크 정보를 먼저 변경해 주세요.')
      }
      authModule.logout()
      return
    }

    if (chainId != '' && chainId != _chainId) {
      alert('네트워크가 변경되어 로그아웃 합니다.')
      authModule.logout()
    } else {
      if (account == null) {
        return
      }
      store.dispatch('authStore/setChainId', _chainId)
       store.dispatch('authStore/setMainnetYN', mainnet_yn)
    }

  }

  async handleAccountsChanged(account) {
    let blockchain_cd = store.getters['authStore/getBlockchainCd']

    if (blockchain_cd != 'KLY')
      return

    this.accountCompare(account, this.wallet.networkVersion)
  }

  async accountCompare(account, chainId) {
    const walletAddress = store.getters['authStore/getWalletAddress']
    const mainChainId = '8217'
    const mainnet_yn = (chainId == mainChainId) ? 'Y' : 'N'

    // 메인넷 사용여부 설정에 따라 해당 계정만 로그인하도록 막음
    if (mainnet_yn !== APP_MAINNET_YN) {
      if (APP_MAINNET_YN == 'N') {
        alert('현재 DCL은 테스트넷만 로그인 가능합니다.\n지갑 네트워크 정보를 먼저 변경해 주세요.')
      } else {
        alert('현재 DCL은 메인넷만 로그인 가능합니다.\n지갑 네트워크 정보를 먼저 변경해 주세요.')
      }
      authModule.logout()
      return
    }

    if (account == null && walletAddress != null) {
      authModule.logout()
    } else if (account !== walletAddress) {
      const blockchain_cd = 'KLY'
      const walletInfo = {wallet_address: account, mainnet_yn: mainnet_yn, blockchain_cd: blockchain_cd}

      // 가입여부 확인 후 회원가입 or 로그인
      await authModule.memberCheck(walletInfo)
    }
  }

  async connectWallet(){
    try {
      const accounts = await this.wallet.enable()
      const chainId = String(this.wallet.networkVersion)

      this.accountCompare(accounts[0], chainId)
      
      store.dispatch('authStore/setChainId', chainId)
      const mainChainId = '8217'
      const mainnet_yn = (chainId == mainChainId) ? 'Y' : 'N'
      store.dispatch('authStore/setMainnetYN', mainnet_yn)
    } catch (err) {
      console.error(err)
    }
  }

  async join(){
    try {
      const accounts = await this.wallet.enable()
      const account = accounts[0]
      const chainId = String(this.wallet.networkVersion)
      const blockchain_cd = 'KLY'
      const mainChainId = '8217'
      const mainnet_yn = (chainId == mainChainId) ? 'Y' : 'N'
  
      // 메인넷 사용여부 설정에 따라 해당 계정만 로그인하도록 막음
      if (mainnet_yn !== APP_MAINNET_YN) {
        if (APP_MAINNET_YN == 'N') {
          alert('현재 DCL은 테스트넷만 가입 가능합니다.\n지갑 네트워크 정보를 먼저 변경해 주세요.')
        } else {
          alert('현재 DCL은 메인넷만 가입 가능합니다.\n지갑 네트워크 정보를 먼저 변경해 주세요.')
        }
        authModule.logout()
        return
      }

      const walletInfo = {wallet_address: account, mainnet_yn: mainnet_yn, blockchain_cd: blockchain_cd}
      // 회원가입
      authModule.join(walletInfo)

    } catch (err) {
      console.error(err)
    }
  }

  installWallet(){
    window.open('https://chrome.google.com/webstore/detail/kaikas/jblndlipeogpafnldhgmapagcccfchpi?hl=ko')
  }

}
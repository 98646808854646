import axios from 'axios'
import { authModule } from '@/module/authModule'
import { API_URL} from '@/assets/js/url'

let refresh = false

axios.defaults.baseURL = API_URL
axios.defaults.withCredentials = true

axios.interceptors.response.use(
	(response) => response,
	async (error) => {
		if(error.response.data.code === '4011' && !refresh) {
			console.info('토큰이 만료되었습니다. 재발급 요청합니다.')
			refresh = true
			const originRequest = error.config
			const res = await authModule.renewToken()

			if(res.status === 200) {
				originRequest.headers.Authorization = `Bearer ${res.data.access_token}`
				return axios(originRequest)
			}


		} else if (error.response.data.code === '4010' || error.response.data.code === '4012') {
			console.info('토큰이 유효하지 않습니다. 로그아웃합니다.')
			authModule.logout()

		} else if (error.response.data.code === '4011') {

			console.info('토큰이 만료되었습니다.')
			authModule.logout()
		}


		return {... error.response}
	},
)
